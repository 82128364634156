<template>
  <div>

    <div class="container">
      <div class="row ">
        <div class="col-12 align-content-center justify-content-center">
          <h1>Транскрибация mp3, mp4, m4a, ogg (до 1gb) <br> с помощью whisper</h1>
        </div>
      </div>
    </div>

    <div class="container" v-if="jobIsNotDone">


      <div class="p-4">
        <form>


          <div class="row">
            <div class="col-3">
              <vs-upload automatic class="text-info" :action="'https://8m-ai-marketing.ru/whisper/whisper/run'"
                fileName='file' :data="avatarForm" text='Загрузить файл' />
              <!-- <vs-upload automatic class="text-info"
                       :action="'http://localhost:8072/whisper/run'"
                       fileName='file' :data="avatarForm" text='Загрузить файл'
            />-->
            </div>
            <div class="col-4"></div>
            <div class="col-5">
              <div class="form-group">
                <label>Youtube URL</label>
                <div class="position-relative">
                  <message-circle-icon class="fea icon-sm icons"></message-circle-icon>
                  <input name="keys" id="keys" class="form-control pl-5" placeholder="https://youtu.be/3nfadIylKwc"
                    v-model="input_api.youtube_url" />
                </div>
              </div>
            </div>

          </div>

          <div class="row mt-4">
            <div class="col-7">

            </div>
            <div class="col-5">
              <a id="div-with-loading-youtube" class="btn btn-secondary vs-con-loading__container" @click="getYoutube">
                Транскирибировать Youtube</a>

            </div>

          </div>
          <!--end row-->
        </form>
        <!--end form-->
      </div>



    </div>
    <div v-else class="row m-12 justify-content-center align-items-center">

      <b-dropdown class="btn-group mr-2 mt-2" variant="primary" text="Скачать">
        <b-dropdown-item @click="downloadFile('docx')">.docx</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click="downloadFile('txt')">.txt</b-dropdown-item>
      </b-dropdown>

    </div>

    <div class="row m-12 mb-5 justify-content-center align-items-center" v-if="showStatus">
      {{ status_msg }}
    </div>


    <div class="row m-12 justify-content-center align-items-center">


      <div class="col-11">
        <div class="table-responsive bg-white shadow rounded">
          <table class="table mb-0 table-center" style="width:100%;">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Слушать</th>
                <th scope="col"> Текст</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(x, ix) in speech2Text">

                <td>{{ ix + 1 }}</td>

                <td>
                  <player :src="urlRadio(x.file_name)" />
                </td>

                <td>
                  <div v-html="x.text_transcribe"></div>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </div>





    </div>



  </div>
</template>

<script>
import store from "@/store/store";
import { MessageCircleIcon, BookIcon, DownloadIcon, } from "vue-feather-icons";
import player from '../player/player'
let sseClient;

export default {
  components: {
    player,
    MessageCircleIcon, BookIcon, DownloadIcon,
  },
  name: "SoundFile2Text",
  data() {
    return {
      jobIsNotDone: true,
      input_api: {
        user_id: 1,
        hash: '',
        youtube_url: '',
      },
      tableShow: false,

      status: {
        rows_in: 0, rows_out: 0,
      },
      token: '',
      excelResults: [],
      refreshTime: 1,

      speech2Text: [],
      hash: '',

      showStatus: false,
      status_msg: '',

      status_arr: [],
    }
  },


  computed: {
    avatarForm: function () {
      console.log('inside avatarForm');
      return {
        user_id: store.state.user_id,
        hash: this.hash,
      }
    },
  },


  created() {
    if (this.$route.params.hash.length > 1) {
      this.hash = this.$route.params.hash;
    } else {
      this.hash = this.$CryptoJS.MD5(store.state.user_id + new Date()).toString();
    }

    console.log(' hash ' + this.hash);
  },
  mounted() {
    this.getHistory();

    sseClient = this.$sse.create({
      url: '' + this.$hostnamejava + '/s2t/stream-sse-mvc/' + this.hash,
      format: 'plain',
      withCredentials: false,
      polyfill: true,
    });

    sseClient.on('message', this.handleMessage);

    sseClient.connect()
      .then(sse => {
        console.log('We\'re connected!');
      })
      .catch((err) => {
        console.error('Failed to connect to server', err);
      });
  },
  beforeDestroy() {

    sseClient.disconnect();

  },
  methods: {

    handleBan(banMessage) {
      this.messages.push(`You've been banned! Reason: ${banMessage.reason}`);
    },
    handleChat(message) {
      this.messages.push(`${message.user} said: ${message.text}`);
    },
    handleMessage(message, id) {
      console.log(" message: " + ' ' + id);
      console.log(message)
      if (message.includes("status")) {
        this.status_arr = JSON.parse(message);
        //console.log(this.status_arr)
        for (let i = 0; i < this.status_arr.status.length; i++) {

          if (parseInt(this.status_arr.status[i].user_id) === parseInt(store.state.user_id)) {
            if (parseInt(this.status_arr.status[i].status) >= 5) {
              this.status_msg = 'В очереди на обработку ' + this.status_arr.status[i].status + ' файлов'
            } else if ((parseInt(this.status_arr.status[i].status) < 5) & (parseInt(this.status_arr.status[i].status) >= 2)) {
              this.status_msg = 'В очереди на обработку ' + this.status_arr.status[i].status + ' файла'
            } else if (parseInt(this.status_arr.status[i].status) === 1) {
              this.status_msg = 'В очереди на обработку ' + this.status_arr.status[i].status + ' файл'
            }
            this.showStatus = true;
            console.log(' i = ' + i + ' ' + this.status_arr.status[i].user_id + ' ' + store.state.user_id + ' ' + this.status_msg + ' ' + this.showStatus)
          }
        }
      } else {
        let transcribedFile = JSON.parse(message);
        this.speech2Text.push(transcribedFile);
        this.speech2Text = this.speech2Text.sort((a, b) => (a.file_name > b.file_name) ? 1 : -1);
        if (transcribedFile.lastFileFlag) {
          this.showStatus = false;
          this.status_msg = '';
          this.$vs.notify({
            time: 8000,
            title: 'Успех',
            text: 'Транскрибация готова',
            color: "success"
          });
          this.$vs.loading.close("#div-with-loading-youtube > .con-vs-loading");
          this.jobIsNotDone = false;
        }
      }
    },


    getYoutube() {
      this.$vs.loading({
        container: '#div-with-loading-youtube',
        scale: 0.5,
        opacity: 0.1,
      });
      this.input_api.user_id = store.state.user_id;
      this.input_api.hash = this.hash;

      this.axios({
        method: 'post',
        url: '' + 'https://8m-ai-marketing.ru/whisper/whisper/run-youtube',
        data: this.input_api,
      }).then((res) => {
        console.log(res.data)
        this.$vs.notify({
          time: 8000,
          title: 'Ожидайте',
          text: 'Транскрибация поставлена в очередь на обработку',
          color: "warning"
        });
        //this.$vs.loading.close("#div-with-loading-youtube > .con-vs-loading");
      });

    },


    urlRadio(filename) {
      return '' + this.$hostnamejava + '/radio-s3/stream-file/whisper/' + filename;
    },


    downloadFile(x) {

      this.input_api.user_id = store.state.user_id;
      this.input_api.project_hash = this.$route.params.hash;
      if (x === 'docx') {
        this.axios({
          method: 'post',
          url: '' + this.$hostnamejava + '/whisper/download/docx-results',
          data: this.input_api,
          responseType: "blob",
        }).then(function (response) {
          const blob = new Blob(
            [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
          const aEle = document.createElement('a');
          const href = window.URL.createObjectURL(blob);
          aEle.href = href;
          aEle.download = "result.docx";
          document.body.appendChild(aEle);
          aEle.click();
          document.body.removeChild(aEle);
          window.URL.revokeObjectURL(href);
        })
      } else {
        this.axios({
          method: 'post',
          url: '' + this.$hostnamejava + '/whisper/download/text-results',
          data: this.input_api,
          responseType: "blob",
        }).then(function (response) {
          const blob = new Blob(
            [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
          const aEle = document.createElement('a');
          const href = window.URL.createObjectURL(blob);
          aEle.href = href;
          aEle.download = "result.txt";
          document.body.appendChild(aEle);
          aEle.click();
          document.body.removeChild(aEle);
          window.URL.revokeObjectURL(href);
        })
      }
    },



    getHistory() {

      this.input_api.user_id = store.state.user_id;
      this.input_api.project_hash = this.$route.params.hash;

      this.axios({
        method: 'post',
        url: '' + this.$hostnamejava + '/whisper/get-results',
        data: this.input_api,
      }).then((res) => {

        this.speech2Text = res.data.results;
        console.log(this.speech2Text)
      });

    },



  }
}
</script>

<style scoped></style>